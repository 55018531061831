import React from "react"
import Layout from "../../components/Layout"
import PageTitle from "../../components/PageTitle"
import ContentBox from "../../components/Content-box"

const PrivacyEnKlachten = () => {
  return (
    <Layout title="Privacy en klachten">
      <PageTitle title="Privacyreglement en klachtenregeling" />
      <ContentBox>
        <h3>Privacyreglement en klachtenregeling</h3>
        <p>
          Onze praktijk fysiotherapie Wijnandsrade-Nuth houdt een registratie
          bij van medische en administratieve zaken. Op de registratie is de wet
          Bescherming Persoonsgegevens van toepassing (WBP).Deze wet bevat
          regels met betrekking tot het doel van de registratie, de inhoud van
          de gegevens en het beheer van de gegevens, de personen welke toegang
          en inzagerecht hebben tot deze gegevens. Ook uw inzagerecht is hier
          vastgelegd.
        </p>
        <p>
          Alleen gegevens die te maken hebben met de behandeling worden in het
          medisch dossier opgeslagen. U heeft recht deze gegevens in te zien.
          Indien u meent dat de gegevens onjuist zijn vastgelegd, kunt u de
          behandelend fysiotherapeut verzoeken deze te wijzigen. Indien u van
          mening bent dat de praktijk niet op de juiste wijze met uw gegevens
          omgaat kunt u dit kenbaar maken via de geldende klachtenregeling van
          de praktijk.
        </p>
        <p>
          Wij proberen ons werk zo goed mogelijk te doen. Desondanks kunnen
          zaken niet lopen naar uw tevredenheid en of verwachtingen. Wij hopen
          dat u dit bij ons meldt, zodat we actie kunnen ondernemen. Komen we er
          dan niet uit dan kunt u een officiële klacht indienen bij de
          klachtenregeling nam het Koninklijk Nederlands Genootschap voor
          fysiotherapie. Op de website{" "}
          <a href="https://www.defysiotherapeut.com">
            www.defysiotherapeut.com
          </a>{" "}
          kunt u lezen hoe dit moet.
        </p>
        <ul>
          De volgende reglementen zijn van toepassing;
          <li>klachtenregeling KNGF</li>
          <li>De Wet Geneeskundige Behandelingsovereenkomst (WGBO)</li>
          <li>Wet bescherming Persoonsgegevens (WPB)</li>
          <li>Wet Klachtrecht CliëntenZorgsector (wkcz)</li>
          <li>Kwaliteitswet</li>
        </ul>
      </ContentBox>
    </Layout>
  )
}

export default PrivacyEnKlachten
